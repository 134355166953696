import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Dropdown, Col, Row } from "react-bootstrap"
import "./MortgageForm.scss"
import { calculateMonthlyPayment } from "../calculators/calculators"
import EnquireModal from "../EnquireModal/EnquireModal";

const OffplanForm = props => {
  const [modalShowEnquire, setModalShowEnquire] = useState(false)

  const filterNumber = str => {
    if (!str) return 0
    str = str.toString()
    return parseInt(str.replace(/[^\d.]/g, ""), 10)
  }

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AED",
  })

  const residentTypes = {
    RESIDENT: {
      label: "Resident of Dubai",
      downPaymentPercentage: 10,
    },
    NON_RESIDENT: {
      label: "Non Resident of Dubai",
      downPaymentPercentage: 50,
    },
  }

  const addCommas = value => {
    return parseInt(value).toLocaleString()
  }

  const [loanValue, setloanValue] = useState(
    props.price?.toString() || "3000000"
  )
  const [loanValueComma, setloanValueComma] = useState(
    addCommas(props.price?.toString()) || "3,000,000"
  )

  const [monthlyRepayment, setMonthlyPayment] = useState(13207.03)

  const [interestRate, setinterestRate] = useState(3)
  const handleChangeInterestRate = e => {
    setinterestRate(e.target.value)
  }

  const [residentType, setResidentType] = useState(residentTypes.RESIDENT)

  const [downPaymentPercentage, setDownPaymentPercentage] = useState(
    residentType.downPaymentPercentage
  )

  useEffect(() => {
    setdownPayment(getDownPayment(loanValue, residentType))
    setDownPaymentPercentage(residentType.downPaymentPercentage)
    setRemLoanAmt(getRemLoanAmt(loanValue, downPayment))
  }, [residentType, loanValue])

  const handleChangeDownPayment = value => {
    // if (value >= loanValue) return

    if (value.length == 0) {
      setdownPaymentComma(addCommas("0"))
    } else {
      setRemLoanAmt(parseInt(loanValue) - parseInt(value))
      setdownPaymentComma(addCommas(value))
    }

    setdownPayment(value)
  }

  const getRemLoanAmt = (total, dpVal) => {
    console.log("sss total", total)
    console.log("sss dp", getDownPayment(total, residentType))
    console.log(
      "sss rem",
      parseInt(total, 10) - getDownPayment(total, residentType)
    )
    return parseInt(total, 10) - getDownPayment(total, residentType)
  }

  const getDownPayment = (total, residentType) => {
    return Math.ceil(
      parseInt(total, 10) * (residentType?.downPaymentPercentage / 100)
    )
  }

  const [downPayment, setdownPayment] = useState(
    getDownPayment(loanValue, residentType)
  )
  const [downPaymentComma, setdownPaymentComma] = useState(
    addCommas(downPayment)
  )
  useEffect(() => {
    setdownPaymentComma(addCommas(downPayment))
  }, [downPayment])

  const handleChangeRemAmt = value => {
    if (value.length == 0) {
      setRemLoanAmtComma(addCommas("0"))
    } else {
      setRemLoanAmtComma(addCommas(value))
    }

    setRemLoanAmt(value)
  }

  const [remLoanAmt, setRemLoanAmt] = useState(
    getRemLoanAmt(loanValue, downPayment)
  )
  const [remLoanAmtComma, setRemLoanAmtComma] = useState(addCommas(remLoanAmt))

  useEffect(() => {
    setRemLoanAmtComma(addCommas(remLoanAmt))
  }, [remLoanAmt])

  const handleChangeLoanValue = value => {
    if (value.length == 0) {
      setloanValueComma(addCommas("0"))
    } else {
      setloanValueComma(addCommas(value))
    }

    setloanValue(value)
  }

  const [duration, setduration] = useState(25)

  const handleChangeResident = value => {
    if (value === residentTypes.RESIDENT.label)
      setResidentType(residentTypes.RESIDENT)
    else setResidentType(residentTypes.NON_RESIDENT)
  }

  const handleChangeduration = value => {
    setduration(value)
  }

  const handleSubmit = () => {
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(loanValue)),
        parseFloat(interestRate),
        parseInt(filterNumber(downPayment)),
        duration
      )
    )
  }
  const url = typeof window !== "undefined" ? window.location.href : ""
  const trackevent = () => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "Mortgage Calculator",
        formType: url,
        formId: "Mortgage Calculator",
        formName: "Mortgage Calculator",
        formLabel: "Mortgage Calculator",
      })
    }
  }
  useEffect(() => {
    handleSubmit()
    trackevent()
    //console.log("testing122", loanValue, interestRate, duration, downPayment)
  }, [loanValue, interestRate, duration, downPayment])

  useEffect(() => {
    if (props.price) {
      setloanValue(props.price)
    }
    handleSubmit()
  }, [])

  const checkNaN = value => {

    return value === "NaN" ? "0" : value
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg={4} md={6} className="field-section">
            <div className="input-wrap">
              <label>Resident Status (?):</label>
              <Dropdown
                onSelect={handleChangeResident}
                className="select-option"
              >
                <Dropdown.Toggle id="dropdown-basic">
                  {residentType.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey={residentTypes.RESIDENT.label}>
                    {residentTypes.RESIDENT.label}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={residentTypes.NON_RESIDENT.label}>
                    {residentTypes.NON_RESIDENT.label}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col lg={4} md={6} className="field-section">
            <div className="input-wrap">
              <label>Purchase Price (AED):</label>
              <input
                placeholder="4,550,000"
                value={loanValueComma}
                onChange={e => {
                  const val = e.target.value.split(",").join("")
                  // handleChangeLoanValue(val)
                  const re = /^[0-9]*$/

                  if (re.test(val)) {
                    handleChangeLoanValue(val)
                  }
                }}
              />
            </div>
          </Col>
          <Col lg={4} md={6} className="field-section">
            <div className="input-wrap down-payment-wrap">
              <label>Down Payment (AED):</label>
              <div className="down-payment-box">
                <input
                  placeholder="450,000"
                  onChange={e => {
                    const val = e.target.value.split(",").join("")
                    // handleChangeLoanValue(val)
                    const re = /^[0-9]*$/

                    if (re.test(val)) {


                      if (parseInt(val) < parseInt(loanValue))
                        handleChangeDownPayment(val)
                    }
                  }}
                  value={checkNaN(downPaymentComma)}
                />
                <div className="vertical-divider"></div>
                <p className="dp-percentage">{downPaymentPercentage}%</p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} className="field-section">
            <div className="input-wrap">
              <label>Sum of Loan Amount (AED):</label>
              <input
                placeholder="450,000"
                // onChange={e => {
                //   const val = e.target.value.split(",").join("")
                //   // handleChangeLoanValue(val)
                //   const re = /^[0-9]*$/

                //   if (re.test(val)) {
                //     handleChangeRemAmt(val)
                //   }
                // }}
                value={checkNaN(remLoanAmtComma)}
                disabled
              />
            </div>
          </Col>
          <Col lg={4} md={6} className="field-section">
            <div className="input-wrap">
              <label>Duration (Years):</label>
              <input
                placeholder="25"
                value={duration}
                onChange={e => {
                  const val = e.target.value.split(",").join("")
                  // handleChangeLoanValue(val)
                  const re = /^[0-9]*$/

                  if (re.test(val)) {
                    handleChangeduration(val)
                  }
                }}
              />
              {/* <Dropdown
                  onSelect={handleChangeduration}
                  className="select-option"
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {duration}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="25">25</Dropdown.Item>
                    <Dropdown.Item eventKey="30">30</Dropdown.Item>
                    <Dropdown.Item eventKey="35">35</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
            </div>
          </Col>
          <Col lg={4} md={6} className="field-section">
            <div className="input-wrap">
              <label>Interest Rate (%)</label>
              <input
                type="number"
                placeholder="3"
                onChange={handleChangeInterestRate}
                value={interestRate}
              />
            </div>
          </Col>
        </Row>
        <Row className="result-section-wrap">
          <Col lg={4} >
            <div className="calc-payment-text">Monthly Repayments:</div>
            <div className="calc-price-wrap">
              {checkNaN(formatter.format(monthlyRepayment))}
            </div>
            <div className="calc-cta-text">
              This calculation is for illustration purposes and should be used
              as a guide only.
            </div>
          </Col>
          <Col lg={4}></Col>
          <Col lg={4}>
            <div className="calc-text">Ready to take the next step?</div>
            <div className="calc-btn-wrap">
                <a href="javascript:void(0)" onClick={() => setModalShowEnquire(true)} className="btn btn-black w-100">
                  Get Pre-approved
                </a>
            </div>
          </Col>
        </Row>
      </form>
      <EnquireModal show={modalShowEnquire} onHide={() => setModalShowEnquire(false)} />
    </>
  )
}

export default OffplanForm