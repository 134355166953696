import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import investorsImg from "../../images/off-plan-investors.png";
import EnquireModal from "../EnquireModal/EnquireModal";

const OffplanInvestors = () => {
    const [modalShowEnquire, setModalShowEnquire] = useState(false)

    return (
        <section className="offplan-investors-wrapper">
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="offplan-investors-img-wrapper">
                            <div className="offplan-investors-img-zoom">
                                <img src={investorsImg} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={5}>
                        <h4>For Investors</h4>
                        <h2>Benefits of purchasing property in the UAE</h2>
                        <ul className="offplan-investors-list list-unstyled">
                            <li>Return on investment is 8-15%.</li>
                            <li>Interest-free instalments up to 8 years.</li>
                            <li>A cost increase of residential real estate is 17-25% per year.</li>
                            <li>UAE residence permit in case of purchasing a property from $205,000.</li>
                            <li>No taxes when buying or renting a property.</li>
                            <li>Flexible payment plans.</li>
                        </ul>
                        <a href="javascript:void(0)" onClick={() => setModalShowEnquire(true)} className="btn btn-black">Start Investing</a>
                    </Col>
                </Row>
            </Container>
            <EnquireModal show={modalShowEnquire} onHide={() => setModalShowEnquire(false)} />
        </section>
    )
}

export default OffplanInvestors