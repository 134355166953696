import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import './assets/styles/_index.scss';
import floorImg1 from "../../images/off-plan-floor-img-1.png";
import floorImg2 from "../../images/off-plan-floor-img-2.png";
import floorImg3 from "../../images/off-plan-floor-img-3.png";

const OffplanFloor = (props) => {

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    // const propertyImages = props?.propertyData?.images

    const propertyImages = [
        floorImg1,
        floorImg2,
        floorImg3
    ];

    // var propertyLightImages =
    //     propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    return (
        <>
            {
                props.propertyData?.floorplan.length > 0 &&
                <section className="offplan-floor-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center offplan-floor-content-wrapper">
                                    <h2>Floorplans</h2>
                                    <a href="javascript:void(0)" className="view-more" onClick={e => openPropertyImage(e, 0)}>Enlarge Floorplans</a>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="offplan-floor-slider" {...settings}>
                                    <div className="offplan-floor-slide">
                                        <div className="offplan-floor-img-zoom">
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                                <img src={floorImg1} />
                                            </a>
                                        </div>
                                        <div className="offplan-floor-details">
                                            <div className="offplan-floor-details-wrapper d-flex justify-content-between">
                                                <div className="offplan-bed-details">Studio Apartment</div>
                                                <Link to="#"><i className="icon icon-gallery-enlarge"></i></Link>
                                            </div>
                                            <div className="offplan-floor-size">600-1000 sq.ft/50-90 sq.m</div>
                                        </div>
                                    </div>
                                    <div className="offplan-floor-slide">
                                        <div className="offplan-floor-img-zoom">
                                            <Link to="#">
                                                <img src={floorImg2} />
                                            </Link>
                                        </div>
                                        <div className="offplan-floor-details">
                                            <div className="offplan-floor-details-wrapper d-flex justify-content-between">
                                                <div className="offplan-bed-details">1 Bedroom Apartment</div>
                                                <Link to="#"><i className="icon icon-gallery-enlarge"></i></Link>
                                            </div>
                                            <div className="offplan-floor-size">600-1000 sq.ft/50-90 sq.m</div>
                                        </div>
                                    </div>
                                    <div className="offplan-floor-slide">
                                        <div className="offplan-floor-img-zoom">
                                            <Link to="#">
                                                <img src={floorImg3} />
                                            </Link>
                                        </div>
                                        <div className="offplan-floor-details">
                                            <div className="offplan-floor-details-wrapper d-flex justify-content-between">
                                                <div className="offplan-bed-details">2 Bedroom Apartment</div>
                                                <Link to="#"><i className="icon icon-gallery-enlarge"></i></Link>
                                            </div>
                                            <div className="offplan-floor-size">600-1000 sq.ft/50-90 sq.m</div>
                                        </div>
                                    </div>
                                    <div className="offplan-floor-slide">
                                        <div className="offplan-floor-img-zoom">
                                            <Link to="#">
                                                <img src={floorImg2} />
                                            </Link>
                                        </div>
                                        <div className="offplan-floor-details">
                                            <div className="offplan-floor-details-wrapper d-flex justify-content-between">
                                                <div className="offplan-bed-details">1 Bedroom Apartment</div>
                                                <Link to="#"><i className="icon icon-gallery-enlarge"></i></Link>
                                            </div>
                                            <div className="offplan-floor-size">600-1000 sq.ft/50-90 sq.m</div>
                                        </div>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>
                    </Container>

                    {isOpen && (
                        <Lightbox
                            mainSrc={propertyImages[photoIndex]}
                            nextSrc={
                                propertyImages[
                                (photoIndex + 1) % propertyImages.length
                                ]
                            }
                            prevSrc={
                                propertyImages[
                                (photoIndex + propertyImages.length - 1) %
                                propertyImages.length
                                ]
                            }
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex(
                                (photoIndex + propertyImages.length - 1) %
                                propertyImages.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % propertyImages.length)
                            }
                        />
                    )}
                </section>
            }
        </>
    )
}

export default OffplanFloor