import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import PlayVideo from "../PlayVideo/PlayVideo";
import './assets/styles/_index.scss';
import areaImg from "../../images/off-plan-area.png";

const OffplanArea = () => {

    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);
    const [videotour, setVideoTour] = useState(false);

    return (
        <section className="offplan-area-wrapper">
            <Container>
                <Row>
                    <Col lg={7} className="order-lg-1 order-2">
                        <h4>Location</h4>
                        <h2>Dubai Harbour is a luxurious waterfront development by Meraas Holding spanning over 20 million sq. ft.</h2>
                        <p>Dubai Harbour will feature the largest marina in the MENA region with 1,100 berths that can accommodate the biggest private yachts. Amenities on offer at the marina include a sailing club, a helipad, the Grey and Black Water Service, fueling stations, crew facility and a yachting hub. <br /><br /> There will also be two world-class cruise terminals spanning over 120,000 sq. ft. to cater to the complete passenger turnaround of two cruise ships at a time. Each terminal has leisure, retail and entertainment offerings for the ultimate luxurious experience. A sophisticated transportation network will serve the cruise terminal, yacht club, hotels and the airport.</p>
                        <Link to="#" className="btn btn-black">View Area Guide</Link>
                    </Col>
                    <Col lg={1} className="order-lg-2"></Col>
                    <Col lg={4} className="order-lg-3 order-1">
                        <div className="offplan-area-img-wrapper">
                            <div className="offplan-play-wrapper">
                                <a onClick={(e) => { setPlay(true); setVideourl("https://youtu.be/ScMzIvxBSi4"); setVideoTour(false);}}>
                                    <div className="offplan-play d-flex align-items-center justify-content-center"><i className="icon icon-play-dark"></i></div>
                                </a>
                            </div>
                            <div className="offplan-area-img-zoom">
                                <img src={areaImg} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isPlay && videourl &&
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""}/>
            }
        </section>
    )
}

export default OffplanArea