import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import OffplanUnitTypeCard from "./OffplanUnitTypeCard"
import "./assets/styles/_index.scss"
import useUnitTypes from "./useUnitTypes"

const OffplanUnitTypes = props => {
  // const [photoIndex, setPhotoIndex] = useState(0)
  // const [isOpen, setIsOpen] = useState(false)

  const projectID = props.propertyData?.crm_id

  // const allProjects = dataProject?.properties?.filter(projectValue => projectID === projectValue?.master_id);

  const allProjects = useUnitTypes(projectID)

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  return (
    <>
      {allProjects?.length > 0 && (
        <section className="offplan-unit-types-wrapper">
          <Container>
            <Row>
              <Col>
                <div className="d-flex justify-content-between align-items-center offplan-unit-types-content-wrapper">
                  <h2>Unit Types</h2>
                  <Link to="/off-plan-properties/for-sale/in-dubai/" className="view-more d-md-none d-block">
                    View More
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Slider className="offplan-unit-types-slider" {...settings}>
                  {allProjects.map((item, i) => {
                    return <OffplanUnitTypeCard item={item} i={i} />
                  })}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default OffplanUnitTypes
