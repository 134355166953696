import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Row } from "react-bootstrap";
// import { Link } from "gatsby";
import $ from "jquery";
import Share from "../Share/share";
import EnquireModal from "../EnquireModal/EnquireModal";
import BrochureModal from "../EnquireModal/BrochureModal";
import './assets/styles/_index.scss';
import { SaveItem } from "@myaccount/website-service"
import _ from 'lodash';
import { getWhatsAppURL } from '../../common/misc';
import useUnitTypes from '../OffplanUnitTypes/useUnitTypes';
import { phoneNumber } from '../common/site/utils';

function getOffset( el ) {
    let _x = 0;
    let _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

const OffplanSticky = ({propertyData}) => {    
    const stickyRef = useRef();

    const projectID = propertyData?.crm_id
    const allProjects = useUnitTypes(projectID)

    useEffect(() => {
        // Download brochure
        // $(".downloadBrochure").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $(".offplan-brochure-wrapper").offset().top-50
        //     }, 1000);
        // })
        // Download brochure
        
        // Gallery
        $(".galleryScroll").click(function() {
            $('html, body').animate({
                scrollTop: $(".offplan-gallery-wrapper").offset().top-50
            }, 1000);
        })
        // Gallery

        // Unit types
        $(".unitTypesScroll").click(function() {
            $('html, body').animate({
                scrollTop: $(".offplan-unit-types-wrapper").offset().top-50
            }, 1000);
        })
        // Unit types

        // Location
        $(".locationScroll").click(function() {
            $('html, body').animate({
                scrollTop: $(".off-plan-map").offset()?.top-100
            }, 1000);
        })
        // Location

        window.addEventListener("scroll", windowScroll);

        return () => window.removeEventListener("scroll", windowScroll);
    }, []);

    const windowScroll = () => {
        if (stickyRef.current?.classList) {
          const top = getOffset(stickyRef.current).top - 150;
          if(window.pageYOffset > top){
            stickyRef.current.classList.add('is-sticked');
          } else {
            stickyRef.current.classList.remove('is-sticked');
          }
        }
    }

    // Enquire & Brochure modal
    const [modalShowEnquire, setModalShowEnquire] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    // Enquire & Brochure modal

    return (
        <>
            <section className="offplan-sticky" ref={stickyRef}>
                <Container>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-between sticky-section">
                            <ul className="list-inline sticky-list-left d-flex align-items-center">
                                {propertyData?.brochure?.length > 0  && <li className="list-inline-item">
                                    <a href="javascript:void(0)" className="d-flex align-items-center downloadBrochure" onClick={() => setModalShow(true)}><i className="icon icon-bookopen"></i> <span>Download Brochure</span></a>
                                </li>}
                                <li className="list-inline-item d-md-inline-flex d-none">
                                    <a href="javascript:void(0)" className="galleryScroll"><span>Gallery</span></a>
                                </li>
                                {allProjects?.length ? <li className="list-inline-item d-md-inline-flex d-none">
                                    <a href="javascript:void(0)" className="unitTypesScroll"><span>Unit Types</span></a>
                                </li> : <></>}
                                {propertyData?.latitude && propertyData?.longitude && <li className="list-inline-item d-md-inline-flex d-none">
                                    <a href="javascript:void(0)" className="locationScroll"><span>Location</span></a>
                                </li>}
                            </ul>
                            <ul className="list-inline sticky-list-middle">
                                <li className="list-inline-item">
                                    <Share iconclass="icon icon-property-share" text="Share" id={""} />
                                </li>
                                <li className="list-inline-item">
                                    <SaveItem type="property" pid={propertyData.id}>
                                        <a href="javascript:void(0)" className='save-btn'><i className="icon icon-save-black"></i> <span><span className='save-text'>Save</span></span></a>
                                    </SaveItem>
                                </li>
                                <li className="list-inline-item">
                                    <a href={getWhatsAppURL(phoneNumber)}><i className="icon icon-property-whatsapp me-0"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href={`tel:${phoneNumber}`}><i className="icon icon icon-property-phone"></i> <span>{phoneNumber}</span></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" onClick={() => setModalShowEnquire(true)} className="btn btn-black">Enquire</a>
                                </li>
                            </ul>
                            <ul className="list-inline sticky-list-right d-lg-none d-flex align-items-center">
                                <li className="list-inline-item">
                                    <Share iconclass="icon icon-property-share" text="Share" id={""} />
                                </li>
                                <li className="list-inline-item">
                                    <SaveItem type="property" pid={propertyData.id}>
                                        <a href="javascript:void(0)" className='save-btn'><i className="icon icon-save-black"></i> <span><span className='save-text'>Save</span></span></a>
                                    </SaveItem>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <EnquireModal show={modalShowEnquire} onHide={() => setModalShowEnquire(false)} />
            {!_.isEmpty(propertyData.brochure) && <BrochureModal show={modalShow} onHide={() => setModalShow(false)} file_url={propertyData?.brochure[0].url}/>}
        </>
    )
}

export default OffplanSticky