import React, { useState } from 'react';
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import './assets/styles/_index.scss';
import NoImage from "../../images/no-image.png"

const OffplanUnitTypeCard = (props) => {

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.item?.images

    var propertyLightImages =
        propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    return (
        <>
            <div className={`offplan-unit-types-slide`}>
                <div className="offplan-unit-types-img-zoom">
                    <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                        {
                            props.item?.images.length > 0 ?
                            <img src={props.item?.images[0].srcUrl} />
                            :
                            <img src={NoImage} />
                        }
                    </a>
                </div>
                <div className="offplan-unit-types-details">
                    {
                        props.item?.description &&
                        <div className="offplan-bed-details">{props.item?.description}</div>
                    }
                    {
                        props.item?.price &&
                        <div className="offplan-price-details">Starting from {props.item?.currency} {props.item?.price.toLocaleString()}</div>
                    }
                    <ul className="list-inline offplan-features">
                        {
                            props.item?.floorarea_min > 0 &&
                            <li className="list-inline-item">{props.item?.floorarea_min}-{props.item?.floorarea_max} {props.item?.floorarea_type?.replace("squareFeet", "sq.ft")}</li>
                        }
                        {
                            props.item?.bedroom > 0 &&
                            <li className="list-inline-item">{props.item?.bedroom} Bed</li>
                        }
                    </ul>
                </div>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={
                        propertyLightImages.length > 1 ? propertyLightImages[
                        (photoIndex + 1) % propertyLightImages.length
                        ] : undefined
                    }
                    prevSrc={
                        propertyLightImages.length > 1 ? propertyLightImages[
                        (photoIndex + propertyLightImages.length - 1) %
                        propertyLightImages.length
                        ] : undefined
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyLightImages.length - 1) %
                        propertyLightImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
        </>
    )
}

export default OffplanUnitTypeCard