import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import './assets/styles/_index.scss';
import galleryImg1 from "../../images/off-plan-project-img-1.png";
import galleryImg2 from "../../images/off-plan-project-img-2.png"

const OffplanGallery = (props) => {
    
    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 800,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.propertyData?.images

    // const propertyImages = [
    //     galleryImg1,
    //     galleryImg2
    // ];

    var propertyLightImages = propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    return (
        <>
            {
                props.propertyData?.images.length > 0 &&
                <section className="offplan-gallery-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center offplan-gallery-content-wrapper">
                                    <h2>Project Gallery</h2>
                                    <a href="javascript:void(0)" className="view-more" onClick={e => openPropertyImage(e, 0)}>Enlarge Photos</a>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="offplan-gallery-wrapper-slider">
                                    <Slider className="offplan-gallery-slider" {...settings}>
                                        {
                                            props.propertyData?.images.map((imgData, i) => {
                                                return (
                                                    <div className="offplan-gallery-slide">
                                                        <div className="offplan-gallery-img-zoom">
                                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, i)}>
                                                                <img src={imgData?.srcUrl} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className="offplan-gallery-slide">
                                            <div className="offplan-gallery-img-zoom">
                                                <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                                    <img src={galleryImg1} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="offplan-gallery-slide">
                                            <div className="offplan-gallery-img-zoom">
                                                <Link to="#">
                                                    <img src={galleryImg2} />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="offplan-gallery-slide">
                                            <div className="offplan-gallery-img-zoom">
                                                <Link to="#">
                                                    <img src={galleryImg1} />
                                                </Link>
                                            </div>
                                        </div> */}
                                    </Slider>
                                    <div className="offplan-gallery-enlarge d-md-none d-block">
                                        <button onClick={e => openPropertyImage(e, 0)} to="#"><i className="icon icon-gallery-enlarge"></i></button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {isOpen && (
                        <Lightbox
                            mainSrc={propertyLightImages[photoIndex]}
                            nextSrc={
                                propertyLightImages[
                                (photoIndex + 1) % propertyLightImages.length
                                ]
                            }
                            prevSrc={
                                propertyLightImages[
                                (photoIndex + propertyLightImages.length - 1) %
                                propertyLightImages.length
                                ]
                            }
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex(
                                (photoIndex + propertyLightImages.length - 1) %
                                propertyLightImages.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                            }
                        />
                    )}
                </section>
            }
        </>
    )
}

export default OffplanGallery