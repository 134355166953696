import { z } from "zod"

const ERROR = {
  name: "Please enter a valid name",
  email: "Please enter a valid email address",
  telephone: "Please enter a valid phone number",
  message: "Please enter a valid message",
  property_address: "Please enter a valid address",
  date: "Please enter a valid date",
  time: "Please enter a valid time",
}

const SCHEMA = {
  name: z.string({ message: ERROR.name }).min(1, { message: ERROR.name }),
  email: z.string().email({ message: ERROR.email }),
  telephone: z
    .number({ message: ERROR.telephone, invalid_type_error: ERROR.telephone })
    .int({ message: ERROR.telephone })
    .min(4, { message: ERROR.telephone }),
  message: z.string({ message: ERROR.message }),
  property_address: z
    .string({ message: ERROR.property_address })
    .min(1, { message: ERROR.property_address }),
  date: z.string({ message: ERROR.date }),
  time: z.string({ message: ERROR.time }),
  attachment: z
    .any()
    .refine(files => files?.length > 0, "Please select a file.")
    .refine(files => files?.[0]?.size <= 5000000, `Max file size is 5MB.`),
  nationality: z
    .string({ message: ERROR.name })
    .min(1, { message: ERROR.name }),
  // .refine(
  //   files => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
  //   ".jpg, .jpeg, .png and .webp files are accepted."
  // ),
}

export const registerInterestSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
})
