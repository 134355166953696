import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
// import { Link } from "gatsby";
import parse from "html-react-parser";
import { format } from "date-fns"
import './assets/styles/_index.scss';
import { isDesktop } from 'react-device-detect';
import _ from 'lodash';

const OffplanDescription = (props) => {  

    const showReadMore = props.propertyData?.extra?.building_description.length > 400
    const [readMore, setReadMore] = useState(showReadMore)
  

    useEffect(() => {
        const element = document.getElementById('offplan-about-content')
        if (element) {
            if (readMore) {
                element.style.maxHeight = isDesktop ? "93px" : "87px"
            } else {
                element.style.maxHeight = ""
            }
        }
      }, [readMore])

      if(_.isEmpty(props.propertyData)) return null
            
      const showDetails = props.propertyData?.price > 0 || props.propertyData?.area || props.propertyData?.extra?.apartment_description || props.propertyData?.extra?.developer_name || props.propertyData?.extra?.completionDate
      
      if(!(showDetails || props.propertyData?.extra?.building_description)) return null

    return (
        <section className="offplan-description-wrapper">
            <Container>
                {showDetails && <>
                <Row>
                    <Col>
                        <h2 className='property-details-heading'>Project Details</h2>
                    </Col>
                </Row>
                <Row>
                    {
                        props.propertyData?.price > 0 &&
                        <Col lg={4} md={6}>
                            <div className="offplan-project-label d-md-block d-inline-block">Price from:</div>
                            <div className="offplan-project-details d-md-block d-inline-block">{props.propertyData?.currency} {props.propertyData?.price.toLocaleString()}</div>
                        </Col>
                    }
                    {
                        props.propertyData?.area &&
                        <Col lg={4} md={6}>
                            <div className="offplan-project-label d-md-block d-inline-block">Location:</div>
                            <div className="offplan-project-details d-md-block d-inline-block">{props.propertyData?.area}</div>
                        </Col>
                    }
                    {
                        props.propertyData?.extra?.apartment_description &&
                        <Col lg={4} md={6} className="d-md-block d-flex">
                            <div className="offplan-project-label d-md-block d-inline-block">Bedrooms:</div>
                            <div className="offplan-project-details d-md-block d-inline-block">{props.propertyData?.extra?.apartment_description}</div>
                        </Col>
                    }
                    {
                        props.propertyData?.extra?.developer_name &&
                        <Col lg={4} md={6}>
                            <div className="offplan-project-label d-md-block d-inline-block">Developer:</div>
                            <div className="offplan-project-details d-md-block d-inline-block">{props.propertyData?.extra?.developer_name}</div>
                        </Col>
                    }
                    {
                        props.propertyData?.extra?.completionDate &&
                        <Col lg={4} md={6}>
                            <div className="offplan-project-label d-md-block d-inline-block">Completion Date:</div>
                            <div className="offplan-project-details d-md-block d-inline-block">{format(new Date(props.propertyData?.extra?.completionDate), "MMMM yyyy")}</div>
                        </Col>
                    }
                    {
                        props.propertyData?.extra?.total_floors &&
                        <Col lg={4} md={6}>
                            <div className="offplan-project-label d-md-block d-inline-block">Total Floors:</div>
                            <div className="offplan-project-details d-md-block d-inline-block">{props.propertyData?.extra?.total_floors}</div>
                        </Col>
                    }
                </Row>
                </>}
                {props.propertyData?.extra?.building_description && <Row>
                    <Col>
                        <div className="offplan-about">
                            {
                                props.propertyData?.description &&
                                <h2>About {props.propertyData?.description}</h2>
                            }
                            <>
                                <p id='offplan-about-content' className={`description ${showReadMore ? "description-read-more" : ""} ${readMore ? "read-more" : ""}`}>{parse(props.propertyData?.extra?.building_description)}</p>
                                {showReadMore && (
                                    <button
                                        onClick={() => setReadMore(val => !val)}
                                        className={`read-more-button ${readMore ? "" : "read-less"}`}
                                    >
                                        {readMore ? "read more" : "read less"}
                                    </button>
                                )}
                            </>
                        </div>
                    </Col>
                </Row>}
            </Container>
        </section>
    )
}

export default OffplanDescription