import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { getIconName } from '../common/site/utils';
import './assets/styles/_index.scss';

const OffplanAmenities = (props) => {

    const amenities1 = props.propertyData?.accomadation_summary;
    const amenities2 = props.propertyData?.special;
    const amenitiesFinal = [...amenities1, ...amenities2];

    return (
        <>
            {
                amenitiesFinal?.length > 0 &&
                <section className="offplan-amenities-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <h2>Amenities and features of the project</h2>
                                <ul className="list-unstyled offplan-amenities-list">
                                    {
                                        amenitiesFinal.map((data, i) => {
                                            return (
                                                <li className="d-flex align-items-center">
                                                    {/* <div className="offplan-amenities-bg"></div> */}
                                                    <i className={`icon icon-ammenity-${getIconName(data)}`}></i>
                                                    <div className="offplan-amenities-text">{data}</div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default  OffplanAmenities