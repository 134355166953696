import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { format } from "date-fns"
import './assets/styles/_index.scss';
import OffplanHeader from "../OffplanHeader/OffplanHeader";
import BrochureModal from "../EnquireModal/BrochureModal";
import bannerImg from "../../images/off-plan-img.png";
import bannerLogo from "../../images/off-plan-banner-logo.png";
import NoImage from "../../images/no-image.png";
import _ from 'lodash';
import Header from '../Header/Header';
import { isMobileOnly } from 'react-device-detect';

const OffplanBanner = (props) => {

    // Brochure modal
    const [modalShow, setModalShow] = useState(false)
    // Brochure modal

    const handleForm=()=>{
        const getId=document.getElementById("register-your-interest");
        getId?.scrollIntoView({behavior:"smooth"})
    }
    
    return (
        <>
            <section className="offplan-banner">
                {/* <OffplanHeader /> */}
                <Header layout="off-plan" noScrollAction pid={props.propertyData?.id}/>
                <div className="banner-img-wrapper">
                    {
                        props.propertyData?.images.length > 0 ?
                        <img src={props.propertyData?.images[0].srcUrl} />
                        :
                        <img src={NoImage} />
                    }
                    <div className="overlay-bg"></div>
                </div>
                <div className="banner-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={8}>
                            {props.propertyData?.extra?.developer_logo ? <img src={props.propertyData?.extra?.developer_logo} /> : ''}
                                <div className="banner-header">
                                    {
                                        props.propertyData?.description &&
                                        <h1>{props.propertyData?.description}</h1>
                                    }
                                    {
                                        props.propertyData?.area &&
                                        <div className="banner-text d-flex align-items-center"><i className="icon icon-offplan-marker"></i> {props.propertyData?.area}</div>
                                    }
                                </div>
                                <ul className="list-unstyled banner-list">
                                    {
                                        props.propertyData?.price > 0 &&
                                        <li>Starting From {props.propertyData?.currency} {props.propertyData?.price.toLocaleString()}</li>
                                    }
                                    {
                                        props.propertyData?.extra?.apartment_description &&
                                        <li>{props.propertyData?.extra?.apartment_description}</li>
                                    }
                                    {
                                        props.propertyData?.extra?.completionDate &&isMobileOnly?
                                        <li> {props.propertyData?.extra?.developer_name} / Completion {format(new Date(props.propertyData?.extra?.completionDate), "MMMM yyyy")} </li>
                                        :
                                        <li>{format(new Date(props.propertyData?.extra?.completionDate), "MMMM yyyy")} Completion / {props.propertyData?.extra?.developer_name}</li>

                                    }
                                </ul>
                                <div className="d-md-flex align-items-center banner-btn d-none">
                                    {/* <Link to={`/book-a-viewing?pid=${props.propertyData?.id}`} className="btn btn-transparent-blur">Request Details</Link> */}
                                    <a href="javascript:void(0)" onClick={()=>handleForm()} className="btn btn-transparent-blur">Request Details</a>
                                    {props?.propertyData?.brochure?.length > 0  && <a href="javascript:void(0)" onClick={() => setModalShow(true)} className="btn btn-transparent-blur">Download Brochure</a>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
{!_.isEmpty(props?.propertyData?.brochure) && <BrochureModal show={modalShow} onHide={() => setModalShow(false)} file_url={props?.propertyData?.brochure[0].url}/>
}        </>
    )
}

export default OffplanBanner