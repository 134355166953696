import { zodResolver } from "@hookform/resolvers/zod"
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { scrollToElement, SendMail } from "../common/site/utils"
import { postFormData } from "./api/Api"
import Recaptcha from "./elements/NewRecaptcha"
import { registerInterestSchema } from "./schemas"

const FORM_CONFIG = {
  form_name: "Register Interest",
  email_temp_user: "register_interest_user",
  email_temp_admin: "register_interest_admin",
  email_subject_user: "Thank you for your interest",
  email_subject_admin: "Register a Interest",
  event_tracking: "register",
}

const RegisterInterestForm = () => {
  const recaptchaRef = useRef()
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const myRef = React.createRef()

  const { register, watch, formState, handleSubmit, reset } = useForm({
    resolver: zodResolver(registerInterestSchema),
  })
  const { errors } = formState

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG }

      postData.property_url =
        process.env.GATSBY_SITE_URL + window.location.pathname
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)

        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + FORM_CONFIG.event_tracking,
          formId: "form-" + FORM_CONFIG.event_tracking,
          formName: FORM_CONFIG.form_name,
          formLabel: FORM_CONFIG.form_name,
        })
      })
    }
  }

  useEffect(() => {
    // reset form and show success after onSubmit is run
    if (formState.isSubmitSuccessful) {
      reset()
      setShowSuccessMsg(true)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      setTimeout(() => setShowSuccessMsg(false), 10000)
    }
  }, [formState, reset])

  return (
    <Form
      className="offplan-register-form"
      onSubmit={handleSubmit(onSubmit)}
      ref={myRef}
    >
      {showSuccessMsg && (
        <p className="success-msg" id="form-success-msg">
          Thank you for your interest. A memeber from our team will contact you
          shortly
        </p>
      )}
      <Form.Group className="form-group">
        <Form.Label>Full Name: *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          {...register("name")}
        />
        {errors?.name && <p className="error-msg">{errors?.name.message}</p>}
      </Form.Group>
      <Row>
        <Col lg={12} md={6}>
          <Form.Group className="form-group">
            <Form.Label>Phone: *</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Phone"
              {...register("telephone", { valueAsNumber: true })}
            />
            {errors?.telephone && (
              <p className="error-msg">{errors?.telephone.message}</p>
            )}
          </Form.Group>
        </Col>
        <Col lg={12} md={6}>
          <Form.Group className="form-group">
            <Form.Label>Email Address: *</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              {...register("email")}
            />
            {errors?.email && (
              <p className="error-msg">{errors?.email.message}</p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <div className="">
        <button type="submit" className="btn btn-white w-100">
          Submit Details
        </button>
        {/* <pre style={{ color: "white" }}>{JSON.stringify(watch(), null, 2)}</pre> */}
        <div className="d-md-flex justify-content-between">
          <div className="terms-text">
            By clicking Submit you agree to our <Link to="#">Terms</Link> and{" "}
            <Link to="#">Privacy Policy</Link>.
          </div>
          <div className="terms-text mobile-text">* required field</div>
        </div>
      </div>
      <div className="d-none">
        <Recaptcha recaptchaRef={recaptchaRef} />
      </div>
    </Form>
  )
}

export default RegisterInterestForm
