import { gql, useQuery } from "@apollo/client"
import React from "react"

const useUnitTypes = projectID => {
  const PROJECT_DETAILS = gql`
    query GetAllProject($masterID: String!) {
      properties(limit: 10, where: { publish: "true", master_id: $masterID }) {
        id
        crm_id
        master_id
        area
        display_address
        description
        title
        bedroom
        floor_area
        floorarea_min
        floorarea_max
        floorarea_type
        slug
        search_type
        publish
        price
        price_qualifier
        currency
        images
        imagetransforms
      }
    }
  `

  const { data: dataProject } = useQuery(PROJECT_DETAILS, {
    variables: { masterID: projectID },
  })

  if (!dataProject) {
    return null
  }

  const allProjects = dataProject?.properties?.filter(
    projectValue => projectID === projectValue?.master_id
  )

  return allProjects
}

export default useUnitTypes
