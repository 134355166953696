import _ from 'lodash';
import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import BrochureModal from "../EnquireModal/BrochureModal";
// import { Link } from "gatsby";
import './assets/styles/_index.scss';

const OffplanBrochure = (props) => {
    
    // Brochure modal
    const [modalShow, setModalShow] = useState(false)
    // Brochure modal
    
    if(props?.propertyData?.brochure?.length > 0 !== true) return null

    return (
        <>
            {
                // props.propertyData?.brochure?.length > 0 &&
                <section className="offplan-brochure-wrapper">
                    <Container>
                        <Row className="d-flex align-items-center">
                            <Col md={8}>
                                <div className="offplan-brochure-text">To view all of the project floorplans, download our Floorplans Brochure</div>
                            </Col>
                            <Col md={4}>
                                <a href="javascript:void(0)" onClick={() => setModalShow(true)} className="btn btn-white">Download Brochure</a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
            <BrochureModal show={modalShow} onHide={() => setModalShow(false)} file_url={props?.propertyData?.brochure[0].url}/>
        </>
    )
}

export default OffplanBrochure