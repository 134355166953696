import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import './assets/styles/_index.scss';

const options = [
    {
        per: "10%",
        payment: "Down Payment",
        date: "On booking"
    },
    {
        per: "10%",
        payment: "1st Installment",
        date: "August 2022"
    },
    {
        per: "10%",
        payment: "2nd Installment",
        date: "February 2023"
    },
    {
        per: "10%",
        payment: "3rd Installment",
        date: "August 2023"
    },
    {
        per: "10%",
        payment: "4th Installment",
        date: "January 2024"
    },
    {
        per: "10%",
        payment: "5th Installment",
        date: "July 2024"
    },
    {
        per: "10%",
        payment: "6th Installment",
        date: "January 2025"
    },
    {
        per: "10%",
        payment: "7th Installment",
        date: "September 2025"
    },
    {
        per: "20%",
        payment: "Construction Complete",
        date: "March 2026"
    }
]

const OffplanPayment = (props) => {
    return (
        <>
            {
                props.propertyData?.extra.payment1 &&
                <section className="offplan-payment-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="offplan-payment-heading">Payment PLan</h2>
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.propertyData?.extra.payment1 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment1}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment1}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due1}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment2 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment2}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment2}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due2}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment3 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment3}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment3}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due3}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment4 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment4}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment4}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due4}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment5 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment5}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment5}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due5}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment6 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment6}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment6}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due6}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment7 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment7}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment7}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due7}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment8 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment8}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment8}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due8}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment9 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment9}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment9}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due9}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {
                                props.propertyData?.extra.payment10 &&
                                <Col lg={4} xs={6} className="tile-section">
                                    <div className="d-md-flex align-items-center offplan-payment-card">
                                        <div className="offplan-payment-per">{props.propertyData?.extra.payment10}</div>
                                        <div className="offplan-payment-details-wrapper">
                                            <div className="offplan-payment-details">{props.propertyData?.extra.installment10}</div>
                                            <div className="offplan-payment-date">{props.propertyData?.extra.payment_due10}</div>
                                        </div>
                                    </div>
                                </Col>
                            }

                            {/* {
                                options.map((data, i) => {
                                    return (
                                        <Col lg={4} xs={6} key={i}>
                                            <div className="d-md-flex align-items-center offplan-payment-card">
                                                <div className="offplan-payment-per">{data.per}</div>
                                                <div className="offplan-payment-details-wrapper">
                                                    <div className="offplan-payment-details">{data.payment}</div>
                                                    <div className="offplan-payment-date">{data.date}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            } */}
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default OffplanPayment