import React from 'react';
import { Container, Col, Row, Form } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import registerImg from "../../images/off-plan-register.png";
import RegisterInterestForm from '../forms/RegisterInterestForm';

const OffplanRegister = () => {
    return (
        <section className="offplan-register-wrapper" id="register-your-interest">
            <Container>
                <Row>
                    <Col lg={5} className="order-lg-1 order-2">
                        <h4>Enquire</h4>
                        <h2>Register your interest</h2>
                        <RegisterInterestForm />
                    </Col>
                    <Col lg={1} className="order-lg-2"></Col>
                    <Col lg={6} className="order-lg-3 order-1">
                        <div className="offplan-register-img-wrapper">
                            <div className="offplan-register-img-zoom">
                                <img src={registerImg} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OffplanRegister