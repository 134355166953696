import React, { useState } from "react"
import { Container, Col, Row, Tab, Tabs } from "react-bootstrap"
import ReactSelect from "react-select"
import { useMedia } from "react-use"
import OffplanMortgageRepayments from "../MortgageRepayments/OffplanMortgageRepayments"
import OffplanRentalYield from "../MortgageRepayments/OffplanRentalYield"
import "./assets/styles/_index.scss"

const OPTIONS = {
  mortgage: "Mortgage Calculator",
  rentalYeild: "Rental Yeild Calculator",
}

const OffplanCalculator = props => {
  const [selectedOption, setSelectedOption] = useState(OPTIONS.mortgage)

  const isTablet = useMedia("(max-width: 992px)", false)

  const selectOptions = [
    {
      value: OPTIONS.mortgage,
      label: OPTIONS.mortgage,
    },
    {
      value: OPTIONS.rentalYeild,
      label: OPTIONS.rentalYeild,
    },
  ]

  const handleOnChange = key => {
    setSelectedOption(key)
  }
  return (
    <section className="offplan-calc-wrapper">
      <Container>
        <Row>
          <Col>
            <h2>Financing this Project</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {isTablet ? (
              <>
                <ReactSelect
                  options={selectOptions}
                  onChange={option => handleOnChange(option.value)}
                  className={"calculator-select"}
                  classNamePrefix={"calculator-select"}
                  defaultValue={selectOptions[0]}
                  isSearchable={false}
                />
                {selectedOption === OPTIONS.mortgage && (
                  <OffplanMortgageRepayments price={props?.price} />
                )}
                {selectedOption === OPTIONS.rentalYeild && (
                  <OffplanRentalYield price={props?.price} />
                )}
              </>
            ) : (
              <Tabs
                defaultActiveKey={selectedOption}
                className="offplan-calc-tab"
                onSelect={handleOnChange}
              >
                <Tab eventKey={OPTIONS.mortgage} title="Mortgage Calculator">
                  <OffplanMortgageRepayments price={props?.price} />
                </Tab>
                {/* <Tab
                  eventKey={OPTIONS.rentalYeild}
                  title="Rental Yield Calculator"
                >
                  <OffplanRentalYield price={props?.price} />
                </Tab> */}
              </Tabs>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OffplanCalculator
