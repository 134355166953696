import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import OffplanBanner from "../components/OffplanBanner/OffplanBanner";
import OffplanSticky from "../components/OffplanSticky/OffplanSticky";
import OffplanDescription from "../components/OffplanDescription/OffplanDescription";
import OffplanAmenities from "../components/OffplanAmenities/OffplanAmenities";
import OffplanGallery from "../components/OffplanGallery/OffplanGallery";
import OffplanUnitTypes from "../components/OffplanUnitTypes/OffplanUnitTypes";
import OffplanFloor from "../components/OffplanFloor/OffplanFloor";
import OffplanBrochure from "../components/OffplanBrochure/OffplanBrochure";
import OffplanPayment from "../components/OffplanPayment/OffplanPayment";
import OffplanArea from "../components/OffplanArea/OffplanArea";
import PropertyDetailsMap from "../components/PropertyDetailsMap/PropertyDetailsMap";
import OffplanInvestors from "../components/OffplanInvestors/OffplanInvestors";
import OffplanCalculator from "../components/OffplanCalculator/OffplanCalculator";
import OffplanRegister from "../components/OffplanRegister/OffplanRegister";
import OffplanDevelopments from "../components/OffplanDevelopments/OffplanDevelopments";
import FAQ from "../components/FAQ/FAQ";
import Footer from '../components/Footer/Footer';
import SEO from '../components/Seo/seo';
import BrandLogo from "../images/icons/logo.svg"
import { Link } from "gatsby";
import { getWhatsAppURL } from '../common/misc';
import { isMobileOnly } from 'react-device-detect';
import { SaveItem, useUserObjectState, useAuthState, UserObjectStoreProvider } from "@myaccount/website-service";
import useHasScrolled from '../hooks/useHasScrolled';
import EnquireModal from '../components/EnquireModal/EnquireModal';
import { phoneNumber } from '../components/common/site/utils';

const OFF_PLAN_DETAILS = gql`
  query GetProperty ($id: String!) {
    properties(where:{id: $id}) {
      id
      crm_id
      master_id
      search_type
      status
      department
      publish
      price
      price_qualifier
      max_price
      currency
      display_address
      bedroom
      bathroom
      reception
      building
      floor_area
      floorarea_min
      floorarea_max
      floorarea_type
      floorplan
      crm_negotiator_id
      latitude
      longitude
      accomadation_summary
      description
      long_description
      images
      imagetransforms
      video_tour
      virtual_tour
      extra
      epc
      introtext
      address
      area
      available_from
      brochure
      style
      situation
      selling_info
      room_details
      rent
      slug
      special
      title
    }
  }
`;

const OffplanDetailsTemplate = () => {

  const { state, services } = useUserObjectState()
  const { services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
      if (isAuthenticated) {
      // on page load get the users saved objects
          services.getUserobject({ type: "property", state:true })
      }
  }, [])

   // 
   const pageurl = typeof window !== 'undefined' ? window.location.href : ''
   let myid_filt = pageurl.split('?');
   let mypageurl = myid_filt[0];
   let property_slug = mypageurl.split('-');
   let id = property_slug[property_slug.length - 1];
   id = id.replace("/", "");
 
   const { loading, error, data } = useQuery(OFF_PLAN_DETAILS, {
       variables: { id: id }
   });
   // 

  // 
    const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  // 
  
  const scrolled = useHasScrolled()

  // Loading logo
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" className="new-loader">
            <img
              className="logo-white"
              src={BrandLogo}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  // Loading logo


  return (
    <>
      {
        data && data.properties && data.properties.length > 0 ?
        (
          data.properties.map((data, i) => {
            const building=data.extra?.building_type?.length>1?data.extra?.building_type?.join(" and "):data.extra?.building_type[0];
            const buildingType=building?building:"property"
            var searchaction = data?.search_type === "sales"?"for sale":"for rent" 
            var metatile = `Off plan ${buildingType} ${searchaction} with ${data.bedroom} bedrooms in ${data.display_address} at £${data.price.toLocaleString()}`
           var metadesc=`Know the details of off plan ${buildingType} ${searchaction} with ${data.bedroom} bedrooms in ${data.display_address} at £${data.price.toLocaleString()}. Book a viewing to get assistance in finding the right ${buildingType} for you.`
            return (
              <>
              <SEO image={data.images && data.images[0]?.url} title={metatile} description={metadesc} />

              <section className="offplan-details">
                <OffplanBanner propertyData={data} />
                <OffplanSticky propertyData={data}/>
                <OffplanDescription propertyData={data} />
                <OffplanAmenities propertyData={data} />
                <OffplanGallery propertyData={data} />
                <OffplanUnitTypes propertyData={data} />
                <OffplanFloor propertyData={data} />
                <OffplanBrochure propertyData={data} />
                <OffplanPayment propertyData={data} />
                <OffplanArea propertyData={data} />
                {
                  data?.latitude && data?.longitude && (
                    <PropertyDetailsMap 
                      lat={data?.latitude}
                      lng={data?.longitude}
                      offplanMap={"off-plan-map"}
                    />
                  )
                }
                <OffplanInvestors propertyData={data} />
                <OffplanCalculator price={data?.price} />
                <OffplanRegister propertyData={data} />
                <OffplanDevelopments propertyData={data} />
                {/* <FAQ module={"contact"} /> */}
                <Footer layout="off-plan"/>
                {scrolled && <div className="property-btn-wrapper-fixed d-lg-none d-flex justify-content-between">
                  <button className="btn btn-black" onClick={() => setModalShow(true)} ><i className="icon icon-property-envelope"></i> <span className="enq-mobile">Enquire</span></button>
                  <a href={`tel:${phoneNumber}`} className="btn btn-white"><i className="icon icon-property-phone"></i>{isMobileOnly&&<span>Call</span>} <span className="d-md-flex d-none">{phoneNumber}</span></a>
                  <a href={getWhatsAppURL(phoneNumber)} target={"_blank"} className="btn btn-white"><i className="icon icon-property-whatsapp"></i> <span className="whatspp-mobile">Whatsapp</span></a>
                </div>}
              </section>
              </>
            )
          })
        )
        : ""
      }
      <EnquireModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

const OffplanDetails = (props) => {
  return (
    <UserObjectStoreProvider>
      <OffplanDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default OffplanDetails;
