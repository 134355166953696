import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import './assets/styles/_index.scss';
import devImg1 from "../../images/off-plan-dev-img-1.png";
import devImg2 from "../../images/off-plan-dev-img-2.png";
import devImg3 from "../../images/off-plan-dev-img-3.png";
import PropertyCard from '../PropertyCard/PropertyCardOffplan';

const OffplanDevelopments = ({propertyData}) => {

    const currentID = propertyData.id

    const data = useStaticQuery(graphql`
      query GetOtherOffplans {
        glstrapi {
          properties(where: { search_type: "sales", department: "building", publish: "true" }, limit: 7) {
            ...offplanCardDetails
          }
        }
      }
    `)

    const properties = data.glstrapi.properties
    const filteredProperties = properties?.filter(property => property.id !== currentID)

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    // const propertyImages = props?.propertyData?.images

    const propertyImages = [
        devImg1,
        devImg2,
        devImg3
    ];

    // var propertyLightImages =
    //     propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    return (
        <section className="offplan-dev-wrapper">
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between align-items-center offplan-dev-content-wrapper">
                            <h2>Other Off Plan Developments</h2>
                            <Link to="/off-plan-properties/for-sale/in-dubai/" className="view-more">View More</Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Slider className="offplan-dev-slider property-list-wrap" {...settings}>
                            {/* <div className="offplan-dev-slide">
                                <div className="offplan-dev-img-zoom">
                                    <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                        <img src={devImg1} />
                                    </a>
                                </div>
                                <div className="offplan-dev-details">
                                    <div className="offplan-bed-display-address">Lime Gardens at Dubai Hills Estate</div>
                                    <div className="offplan-price-details">Starting From AED 1,100,000</div>
                                    <div className="offplan-bed-details">1, 2, & 3 Bedroom Apartments</div>
                                    <ul className="offplan-dev-contact-details list-inline d-flex align-items-center">
                                        <li className="list-inline-item">
                                            <Link to="#">
                                                <div className="d-flex align-items-center">
                                                    <i className="icon icon-offplan-dev-envelope"></i> <div className="offplan-enquire"> Enquire</div>
                                                </div>
                                            </Link>    
                                        </li>
                                        <li className="list-inline-item">
                                            <Link to="#">
                                                <div className="d-flex align-items-center">
                                                    <i className="icon icon-offplan-dev-phone"></i> <div className="offplan-contact">{phoneNumber}</div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link to="#"><i className="icon icon-offplan-dev-whatsapp"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            {filteredProperties.map(property => {

                                let processedImages = JSON.stringify({})
                                if (property?.imagetransforms?.images_Transforms) {
                                    processedImages = property.imagetransforms.images_Transforms
                                }

                                return <PropertyCard
                                    price={"AED " + property.price.toLocaleString()}
                                    maxPrice={"AED " + property?.max_price?.toLocaleString()}
                                    propertyid={property.id}
                                    location={property.description}
                                    description={property.area}
                                    propertyApartmentDesc={property.extra?.apartment_description}
                                    propertyDeveloper={property.extra?.developer_name}
                                    propertyCompletionDate={property.extra?.completionDate}
                                    bedroomsCount={property.bedroom}
                                    building={property.building}
                                    bathroomsCount={property.bathroom}
                                    receptionsCount={property.reception}
                                    propertyVideo={property.virtual_tour}
                                    propertyImages={property.images}
                                    propertyDetailsLink={`/off-plan-property-for-sale/${property.slug}-${property.id}/`}
                                    propertySearchType={property.search_type}
                                    status={property.status}
                                    sqft={property.size}
                                    processedImages={processedImages}
                                    card_type="similar_property"
                                />
                            }
                            )}

                        </Slider>
                    </Col>
                </Row>
            </Container>

            {isOpen && (
                <Lightbox
                    mainSrc={propertyImages[photoIndex]}
                    nextSrc={
                        propertyImages[
                        (photoIndex + 1) % propertyImages.length
                        ]
                    }
                    prevSrc={
                        propertyImages[
                        (photoIndex + propertyImages.length - 1) %
                        propertyImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyImages.length - 1) %
                        propertyImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyImages.length)
                    }
                />
            )}
        </section>
    )
}

export default OffplanDevelopments